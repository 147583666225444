import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';
import ImgW from '../../components/ImgW';
import { commonHeaderColor } from '../../util';


const MyNeoSurfPage = ({ data }) => (
  <>
    <SEO
      title="Portefeuille électronique de MyNeoSurf – Casino en ligne &amp; Paiements de mise"
      description="Inscris-toi avec le MyNeosurf -  la solution parfaite pour tous vos besoins de jeu. Dépose &amp; retire en toute sécurité via le portefeuille électronique MyNeoSurf."
      alternateLanguagePages={[
        {
          path: 'myneosurf',
          language: 'en'
        },
        {
          path: 'myneosurf',
          language: 'fr'
        },
        {
          path: 'myneosurf',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="Logo du MyNeoSurf" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">MyNeoSurf - La Solution Parfaite du portefeuille Électronique</h1>
            <p className="textColorDark">Si tu as déjà un utilisateur de Neosurf, tu sauras qu’il s’agit d’un système de paiement basé sur des bons. Tu ne sais peut-être pas qu’il existe également un composant en ligne pour le NeoSurf : il s’agit de MyNeoSurf. Tout comme les bons, c’est <strong>sûr, sécurisé et simple</strong>, c’est une méthode de paiement en ligne qui est à la fois rapide et pratique.</p>
            <a href="https://www.myneosurf.com/en_GB" className="button">Obtenez MyNeosurf</a>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">QU’EST-CE QUE LE MYNEOSURF?</h2>
            <p className="textColorDark">MyNeoSurf est essentiellement un <strong>portefeuille électronique</strong>. Tu peux y télécharger des fonds à l’aide de bons de NeoSurf que tu as achetés dans des points de vente. Une fois ton compte en ligne rechargé, tu peux envoyer de l’argent à des amis et effectuer des paiements en ligne. Le portefeuille électronique est actuellement utilisé par plus de 350 000 personnes et peut être utilisé sur plus de 20 000 sites Web, d’Amazon et League of Legends aux sites Web de casino en ligne tels qu’AU Slots, SpaceLilly et Lucky Star Casino. Tu peux même retirer de l’argent aux guichets automatiquement si tu as une carte de Neocash MasterCard.</p>
            <h2 className="smaller textUppercase">POURQUOI LE MYNEOSURF?</h2>
            <ul>
              <li className="textColorDark">Ton portefeuille électronique préféré (Neteller, Skrill) a récemment été interdit d’utiliser dans ton pays?</li>
              <li className="textColorDark">Tu préfères les dépôts sûrs, sécurisés et simples aux sites de jeu en ligne?</li>
              <li className="textColorDark">Tu aimes la possibilité d’encaisser des jetons de casino dans un portefeuille électronique et de retirer des fonds via un guichet automatique?</li>
            </ul>
            <p className="textColorDark">MyNeosurf est probablement la solution idéale! Toutes les fonctions du portefeuille électronique sont là pour toi. Des dépôts sécurisés aux retraits rapides comme l’éclair!</p>
          </div>
        </div>
        <div>
          <Img fluid={data.myneosurfCard.childImageSharp.fluid} alt="Portefeuille électronique en ligne MyNeoSurf &amp; Casinos en ligne" />
        </div>
      </div>
    </ContentRow>
    <ContentRow backgroundColor="#eee" padV>
      <h2 className="smaller textUppercase">COMMENT IL FONCTIONNE</h2>
      <p className="textColorDark">Pour utiliser MyNeoSurf, tu dois t’inscrire et créer ton compte en ligne. Heureusement, cela est très simple et ne devait pas prendre beaucoup de temps. Une fois que ton compte est configuré et prêt à fonctionner, tu peux le financer en utilisant les codes des <strong>bons de NeoSurf</strong>. Par ailleurs, tu peux financer ton compte en utilisant des <strong>cartes de crédit</strong> ou des <strong>virements bancaires</strong>.</p>
      <ImgW center fluid={data.fundAccount.childImageSharp.fluid} alt="Finance ton compte avec bons, cartes ou virement bancaire" />
      <p>Avec ton compte, tu peux facilement gérer tes bons et obtenir de très bons tarifs de change si l’une de tes transactions implique des différences de devise. Pour retirer de l’argent de ton compte MyNeoSurf, tu peux utiliser un virement bancaire (des frais de 1,5% s’appliquent) ou ton Neocash MasterCard (des frais de 2% s’appliquent). Habituellement, lorsque tu utilises des bons de NeoSurf, il te suffit de saisir le code et tu as les fonds ajoutés à ton compte. Avec un compte de MyNeoSurf, tu peux conserver tous tes codes au même endroit et les gérer.</p>
    </ContentRow>
    <ContentRow padV>
      <div className="grid c-2">
        <div>
          <Img fluid={data.neoCash.childImageSharp.fluid} alt="NeoCash Mastercard" />
        </div>
        <div>
          <div className="contentBox">
            <h3 className="smaller textUppercase">NEOCASH MASTERCARD</h3>
            <p>Il s’agit d’une carte de débit liée à ton compte en ligne, MyNeoSurf. Tu peux en faire la demande une fois ton compte est créé. Comme son nom l’indique, c’est un type de MasterCard et elle peut donc être utilisée partout où Mastercard est acceptée. Tu peux l’utiliser à la fois en ligne et hors ligne.</p>
            <p>Comme on a déjà mentionné, tu peux l’utiliser pour effectuer des retraits à l’ATM de ton compte MyNeoSurf. Pour le faire, simplement, tu dois entrer ton code NIP au guichet automatique et de choisir la somme à retirer.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textColorDark">Différence entre Neosurf et MyNeosurf</h2>
      <p>MyNeoSurf, en revanche, n’a aucune de ces limitations. <strong>Le NeoSurf est un bon prépayé</strong> qui, par sa nature, est une solution de transaction assez limitée. Limité par un certain nombre de facteurs comme le fait que tu dois les acheter physiquement (les bons) – ce qui signifie que tu dois planifier et acheter les bons à l’avance. Limité par le fait que tu ne peux envoyer des fonds qu’avec des bons de NeoSurf, mais que tu ne peux pas les recevoir.</p>
      <ImgW center fluid={data.myNeoVsNeo.childImageSharp.fluid} alt="MyNeosurf VS Neosurf" />
      <p>MyNeoSurf, on the other hand, doesn't have any of those limitations. <strong>MyNeoSurf est un portefeuille électronique</strong>. Les portefeuilles électroniques sont des portefeuilles <strong>en ligne / numériques / électroniques</strong>. Tu n’as pas à planifier tes achats à l’avance, tu peux à la fois envoyer et recevoir tes fonds, tu peux même commander une <strong>carte de débit de MyNeoSurf</strong> et dépenser physiquement tes fonds dans les magasins ou même les retirer via un guichet automatique.</p>
      <h2 className="smaller textColorDark">Portefeuilles électroniques 101</h2>
      <p>Les portefeuilles électroniques existent depuis un certain temps maintenant. Ils ont vu le jour pour la première fois à la fin des années 1990, bien qu’ils n’aient pas vraiment été largement adoptés et utilisés par les consommateurs à l’époque. Les progrès de notre technologie, de la vitesse et de l’accessibilité d’Internet ainsi que les connaissances électroniques et numériques des gens ont le changé. Les portefeuilles électroniques sont désormais largement utilisés dans le monde entier, ce qui n’est pas surprenant compte tenu de tous les avantages qu’ils fournissent</p>
      <ul>
        <li className="textColorDark">Ils sont rapides et sécurisés</li>
        <li className="textColorDark">Ils sont largement acceptés</li>
        <li className="textColorDark">Ils peuvent recevoir, envoyer et dépenser des fonds et même suivre efficacement tes habitudes de dépenses</li>
        <li className="textColorDark">Tu peux commander une carte de débit connectée à ton portefeuille électronique et l’utiliser comme tu utilises ta carte bancaire</li>
        <li className="textColorDark">Tu peux facilement transférer tes fonds entre ton portefeuille électronique et ton compte bancaire</li>
      </ul>
      <h3 className="smaller textColorDark">Alors, comment fonctionnent exactement ces portefeuilles électroniques?</h3>
      <p>C’est très simple, en réalité.</p>
      <p>Accèdes à la page Web du portefeuille électronique et crée un compte, comme n’importe quel autre compte (comme un compte de courrier électronique). C’est à peu près cela. Tu es maintenant propriétaire d’un compte de portefeuille électronique. Tu peux te connecter à ton compte et commencer à l’utiliser. La première chose que tu voudras faire est de « recharger » ton compte avec de l’argent. Tu peux simplement <strong>connecter ta carte bancaire</strong> à ton portefeuille électronique – envoie des fonds de ton compte bancaire vers ton compte de portefeuille électronique. Tu as dorénavant un portefeuille électronique actif avec de l’argent dedans ! Tu peux utiliser cet argent sur ton portefeuille électronique pour acheter des produits et des services auprès de millions de boutiques en ligne, de jeux et d’un grand nombre d’autres services en ligne. Tu peux envoyer les fonds à tes amis et à ta famille qui disposent d’un portefeuille électronique ultra-rapide, sécurisé et bon marché. Tu peux également recevoir des fonds de tes amis et de ta famille.</p>
      <ImgW center fluid={data.eWallets.childImageSharp.fluid} alt="Portefeuilles électroniques – largement acceptés en ligne" />
      <p>De plus, tu peux commander une <strong>carte de débit de portefeuille électronique physique et</strong> l’utiliser pour acheter des biens et des produits dans des magasins du monde entier ou même retirer de l’argent à des distributeurs automatiques de billets aux quatre coins du monde. De nos jours, la plupart de portefeuilles électroniques ont même des applications mobiles que tu peux télécharger et installer sur ton téléphone portable pour recevoir, envoyer et dépenser tes fonds quand et où tu es, même en déplacement, ainsi que pour suivre, surveiller et même limiter tes habitudes de dépenses qui peuvent être d’une grande aide pour ton budget mensuel.</p>
      <p>Tout compte fait, les AVANTAGES de l’utilisation du portefeuille électronique dans ta vie quotidienne sont nombreux, tandis que les INCONVÉNIENTS sont inexistants.</p>
      <p>Le portefeuille électronique MyNeoSurf est l’une des solutions de portefeuille électronique la plus récente, prenant le marché comme une tempête.</p>
      <ul>
        <li className="textColorDark">Il est largement accepté.</li>
        <li className="textColorDark">Il est simple, rapide et sécurisé.</li>
        <li className="textColorDark">Il offre une douzaine de langues pour ta commodité.</li>
      </ul>
      <h2 className="smaller textColorDark">MyNeoSurf dans les jeux en ligne</h2>
      <p>Comme déjà mentionné, le portefeuille électronique MyNeoSurf peut être utilisé pour acheter des produits et des services dans un certain nombre de magasins en ligne, de sites de réservation et de jeux ; de l’un des magasins en ligne le plus utilisé – <u>Amazon</u>, à l’un des jeux le plus joué – <u>League of Legends</u>, cependant il peut également être utilisé dans un certain nombre de casinos!</p>
      <p>Quels sont les avantages de MyNeoSurf dans le jeu en ligne, par rapport aux bons d’achat et aux cartes de crédit?</p>
      <h3 className="smaller textColorDark">Portefeuille électronique MyNeoSurf VS Bons</h3>
      <p>Les bons, comme mentionné précédemment, doivent être achetés physiquement à l’avance sur des endroits spécifiques uniquement, tandis que tu peux ‘recharger’ ton MyNeoSurf à tout moment et n’importe où.</p>
      <p>Tu peux utiliser des bons uniquement pour déposer sur ton compte joueur, tandis que tu peux utiliser le portefeuille électronique MyNeoSurf pour les deux, déposer et retirer tes fonds.</p>
      <h3 className="smaller textColorDark">Portefeuille électronique MyNeoSurf VS Carte de Crédit</h3>
      <p>Tu peux utiliser les deux pour les dépôts et les retraits, mais tu attendras tes retraits sur ta carte de crédit pendant des jours, tout en les recevant presque <strong>instantanément sur ton portefeuille électronique MyneoSurf</strong>.</p>
      <p>Selon les processeurs de paiement et ta nationalité, tes cartes de crédit peuvent être acceptées dans certains Casinos et rejetées dans d’autres. Tu peux également avoir le problème que tes dépôts seront acceptés, mais pas les retraits sur la même carte de crédit, encore une fois cela dépende des processeurs de paiement, des licences, de ta banque, etc. Tu n’auras jamais ce problème avec le portefeuille électronique MyNeoSurf. <strong>Le portefeuille électronique MyNeoSurf est déjà accepté dans de nombreux casinos</strong> avec une tendance à être encore plus accepté à l’avenir. S’il est accepté comme méthode de transaction par le casino, tu n’auras jamais de problèmes avec les retraits sur ton portefeuille électronique MyNeoSurf.</p>
      <ImgW center fluid={data.wideRange.childImageSharp.fluid} alt="Une large gamme de casinos en ligne est amicale avec MyNeoSurf" />
      <p>Pour conclure, on doit mentionner encore un autre avantage incroyable du portefeuille électronique MyNeoSurf dans ton jeu en ligne. Cela concerne tes dépenses et ta budgétisation. Ce qui est étonnant, c’est que tu peux <strong>garder une trace de la somme des fonds que tu souhaites allouer à ton jeu</strong> et la garder sous contrôle. Comme il est séparé de ton compte bancaire, tu peux décider à l’avance de la somme des fonds avec lesquels tu souhaites jouer sur une base hebdomadaire ou mensuelle et envoyer uniquement cette somme de ton compte bancaire à ton portefeuille électronique MyNeoSurf. De cette façon, tu peux t’assurer que tu ne joues pas plus que tu ne le souhaites. Incroyable, n’est-ce pas?</p>
      <p>Tu mets de côté une somme prédéterminée pour tes jeux. Si tu perds, tu sais qu’il est le temps d’arrêter puisqu’il n’y a plus de fonds sur ton portefeuille électronique MyNeoSurf. Si tu gagnes, tu peux le dépenser dans des magasins en ligne ou dans des magasins physiques avec ta carte de débit de portefeuille électronique MyNeoSurf, ou même retirer de l’argent à n’importe quel guichet automatique.</p>
      <p className="textAlignCenter"><strong>Recherches-tu des casinos en ligne qui acceptent les dépôts de MyNeoSurf?</strong></p>
      <div className="grid c-4 bigGap">
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.luckystar.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.betbtc.io/sign-up/a48acf12">
              <Img fluid={data.betbtc.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.emu.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.777slotsbay.com/?goft=355fce1f-1701-4bc8-b29d-e3e0ec292198">
              <Img fluid={data.sevenSB.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="http://www.777bay.com/?a_aid=5a6fb263dabe6">
              <Img fluid={data.sevenB.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default MyNeoSurfPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    myneosurfCard: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-card.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 600,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fundAccount: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/Fund-your-account-with-vouchers-cards-or-banks.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 524,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    neoCash: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/neocashmaster.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 505,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    myNeoVsNeo: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/MyNeosurf-VS-Neosurf.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    eWallets: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/E-wallets-Widely-accepted-online.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    wideRange: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/Wide-range-of-online-casinos-are-MyNeoSurf-friendly.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    luckystar: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/lucky-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 328,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    betbtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/betbtc-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 328,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    emu: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/emucasino-logo-bw.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/777bay-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenSB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/777slotsbay-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
